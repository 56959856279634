import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Doughnut } from 'react-chartjs-2';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR";
import AlertPass from './alert-pass/index';
registerLocale("pt-BR", pt);

const OPReport = () => {
    const { addToast } = useToasts();
    const [caProcessa, setCaProcessa] = useState(false);
    const [pedidos, setPedidos] = useState([]);
    const [dataInicial, setDataInicial] = useState(new Date());
    const [dataFinal, setDataFinal] = useState(new Date());
    const [ agendadoQuantidade, setAgendadoQuantidade ] = useState(0);
    const [ agendadoTotal, setAgendadoTotal ] = useState(0);
    const [ agendadoLiquido, setAgendadoLiquido ] = useState(0);
    const [ pagoQuantidade, setPagoQuantidade ] = useState(0);
    const [ pagoTotal, setPagoTotal ] = useState(0);
    const [ pagoLiquido, setPagoLiquido ] = useState(0);

    useEffect(() => {

    }, []);

    function filtrar() {
        setCaProcessa(true);
        var data = {
            data_inicio: dataInicial,
            data_final: dataFinal,
            id_estabelecimento: localStorage.getItem('id_estabelecimento')
        };

        Api.post("pagamentoonline/getReportTransactions", data).then(rps => {

            if (rps.data.status === false) {
                Swal.fire({
                    title: "Erro!",
                    icon: "error",
                    html: rps.data.erro,
                    showCloseButton: true,
                    showCancelButton: false,
                });
            } else {
                setPedidos(rps.data.report);
                processaDados(rps.data.report);
                if(rps.data.report.length===0){
                    Swal.fire({
                        title: "Atenção!",
                        icon: "info",
                        html: 'Nenhum registro encontrado!',
                        showCloseButton: true,
                        showCancelButton: false,
                      });
                }
            }
            setCaProcessa(false);
        });
    }

    function processaDados(dados){
        var countPedidosAgendado = 0;
        var sumValorTotalAgendado = 0;
        var sumValorLiquidoAgendado = 0;
        var countPedidosPago = 0;
        var sumValorTotalPago = 0;
        var sumValorLiquidoPago = 0;

        dados.map( pedido => {
            if (pedido?.status!=='PAGO') {
                countPedidosAgendado++;
                sumValorTotalAgendado+= parseFloat(pedido?.orderAmountTotal);
                sumValorLiquidoAgendado+= parseFloat(pedido?.finalEstablishment);
            } else {
                countPedidosPago++;
                sumValorTotalPago+= parseFloat(pedido?.orderAmountTotal);
                sumValorLiquidoPago+= parseFloat(pedido?.finalEstablishment);
            }
          return null
        });
        setAgendadoQuantidade(countPedidosAgendado);
        setAgendadoTotal(sumValorTotalAgendado);
        setAgendadoLiquido(sumValorLiquidoAgendado);
        setPagoQuantidade(countPedidosPago);
        setPagoTotal(sumValorTotalPago);
        setPagoLiquido(sumValorLiquidoPago);
    }


    return (
        <>
            <div>

                <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
                    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        {/*begin::Details*/}
                        <div className="d-flex align-items-center flex-wrap mr-2">
                            {/*begin::Title*/}
                            <h5 className="text-dark font-weight-bold mt-2 mr-5">Pagamento online</h5>
                            {/*end::Title*/}
                            {/*begin::Separator*/}
                            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
                            {/*end::Separator*/}
                            {/*begin::Title*/}
                            {/*end::Separator*/}
                            {/*begin::Title*/}
                            <span className="breadcrumb breadcrumb-transparent mt-5 mr-5">Relatório</span>
                            {/*end::Title*/}
                        </div>
                        {/*end::Details*/}
                        {/*begin::Toolbar*/}
                        <div className="d-flex align-items-center">

                        </div>
                        {/*end::Toolbar*/}
                    </div>
                </div>
                {/*begin::Entry*/}
                <div className="d-flex flex-column-fluid">
                    {/*begin::Container*/}
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-custom mr-5 pt-5 pl-5 pr-5 pb-5">
                                    <div className="row border-bottom">
                                        <div className="col-md-1">
                                            <label><i className="fas fa-filter mr-3"></i><b>Filtros</b></label>
                                        </div>

                                        <div className="col-md-2 form-group">
                                            <div className="input-group">
                                                <DatePicker
                                                    onChange={setDataInicial}
                                                    isClearable
                                                    selectsStart
                                                    locale="pt-BR"
                                                    className="form-control"
                                                    placeholderText="dd/mm/aaaa"
                                                    selected={dataInicial}
                                                    dateFormat="dd/MM/y"
                                                    startDate={dataInicial}
                                                    endDate={dataFinal}
                                                    customInput={
                                                        <InputMask mask="99/99/9999" />} />
                                            </div>
                                        </div>
                                        <div className="col-md-2 form-group">
                                            <div className="input-group">
                                                <DatePicker
                                                    onChange={setDataFinal}
                                                    isClearable
                                                    locale="pt-BR"
                                                    className="form-control"
                                                    placeholderText="dd/mm/aaaa"
                                                    selected={dataFinal}
                                                    dateFormat="dd/MM/y"
                                                    selectsEnd
                                                    startDate={dataInicial}
                                                    endDate={dataFinal}
                                                    minDate={dataInicial}
                                                    customInput={
                                                        <InputMask mask="99/99/9999" />} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            {/*begin::Button*/}
                                            <button onClick={e => { filtrar(); }} className="btn btn-primary btn-block"><i className="fas fa-filter" /> Filtrar</button>
                                            {/*end::Button*/}
                                            {/*begin::Button*/}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <br />

                        <div className="card card-custom">
                            <div className="ml-10 mr-10 mt-10">

                                <LoadingOverlay
                                    active={caProcessa}
                                    spinner
                                    text='Carregando...'
                                >
                                   <AlertPass/>

                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center">Hash</th>
                                                <th className="text-center">Data</th>
                                                <th className="text-center">Valor da venda</th>
                                                <th className="text-center">Valor líquido</th>
                                                <th className="text-center">Tipo pgto</th>
                                                <th className="text-center">Status</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                pedidos !== null &&
                                                pedidos.map(pedido => {
                                                    var status = pedido.status;
                                                    var pagamento = '';
                                                    var imagemPagamento = '';
                                                    var statusColor = '';
                                                    var textoCor = '';

                                                    if (status === 'PAGO') {
                                                        statusColor = 'success';
                                                    } else if (status === 'AGUARDANDO LOTE') {
                                                        statusColor = 'warning';
                                                    } else {
                                                        statusColor = 'info';
                                                    }

                                                    if (pedido?.paymentType == "pix" || pedido?.paymentType == "picpay" || pedido?.paymentType == "cartão" || pedido?.paymentType == "Nubank") {
                                                        pagamento = 'Online';
                                                        imagemPagamento = 'fas fa-link text-danger';
                                                        textoCor = 'danger';
                                                    }

                                                    return (
                                                        <>
                                                            <tr>
                                                                <td className="text-center">#{pedido.orderHash}</td>
                                                                <td className="text-center">{pedido.transactionDate}</td>
                                                                <td className="text-center">R$ {pedido.orderAmountTotal}</td>
                                                                <td className="text-center">R$ {pedido.finalEstablishment}</td>
                                                                <td className="text-center"><i className={""+imagemPagamento+""} /> <label class={"text-"+textoCor}><b>{pagamento}</b></label></td>
                                                                <td className="text-center">
                                                                    <span className={"label label-inline label-light-"+statusColor+" font-weight-bold"}><b>{status}</b></span>
                                                                </td>
                                                            </tr>
                                                        </>)
                                                })}
                                                {/*
                                            <tr>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </tr>
                                        */}
                                        </tbody>
                                    </table>
                                </LoadingOverlay>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default withRouter(OPReport);
