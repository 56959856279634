import { Link } from "react-router-dom";
import './style.css';
const AlertPass = () => {
  return (
    <div className="container container-alert">
      <div class="alert alert-primary-custom" role="alert">
        <div className="row justify-content-center">
          <div className="col-1 alert-icon-container">
            <svg width="30" height="30" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.25 29.75H22.75V19.25H19.25V29.75ZM21 15.75C21.4958 15.75 21.9118 15.582 22.2478 15.246C22.5838 14.91 22.7512 14.4947 22.75 14C22.7488 13.5053 22.5808 13.09 22.246 12.754C21.9112 12.418 21.4958 12.25 21 12.25C20.5042 12.25 20.0888 12.418 19.754 12.754C19.4192 13.09 19.2512 13.5053 19.25 14C19.2488 14.4947 19.4168 14.9106 19.754 15.2477C20.0912 15.5849 20.5065 15.7523 21 15.75ZM21 38.5C18.5792 38.5 16.3042 38.0403 14.175 37.121C12.0458 36.2017 10.1938 34.9551 8.61875 33.3812C7.04375 31.8074 5.79717 29.9553 4.879 27.825C3.96084 25.6947 3.50117 23.4197 3.5 21C3.49884 18.5803 3.9585 16.3053 4.879 14.175C5.7995 12.0447 7.04609 10.1926 8.61875 8.61875C10.1914 7.04492 12.0435 5.79833 14.175 4.879C16.3065 3.95967 18.5815 3.5 21 3.5C23.4185 3.5 25.6935 3.95967 27.825 4.879C29.9565 5.79833 31.8086 7.04492 33.3813 8.61875C34.9539 10.1926 36.2011 12.0447 37.1228 14.175C38.0444 16.3053 38.5035 18.5803 38.5 21C38.4965 23.4197 38.0368 25.6947 37.121 27.825C36.2052 29.9553 34.9586 31.8074 33.3813 33.3812C31.8039 34.9551 29.9518 36.2022 27.825 37.1227C25.6982 38.0432 23.4232 38.5023 21 38.5ZM21 35C24.9083 35 28.2188 33.6437 30.9312 30.9312C33.6438 28.2187 35 24.9083 35 21C35 17.0917 33.6438 13.7812 30.9312 11.0687C28.2188 8.35625 24.9083 7 21 7C17.0917 7 13.7813 8.35625 11.0688 11.0687C8.35625 13.7812 7 17.0917 7 21C7 24.9083 8.35625 28.2187 11.0688 30.9312C13.7813 33.6437 17.0917 35 21 35Z" fill="#1B78B3" />
            </svg>
          </div>
          <div className="col-11 alert-description-container">
            <span className="alert-description" style={{ fontSize: '14px' }}>
              Os repasses a partir do dia <b>19 de fevereiro de 2025</b>, estão disponíveis para consulta na página <b>'Repasses'</b>, localizada na aba <b>'Financeiro'</b> do menu lateral.
            </span>
            <div>
              <Link
                to="/financeiro/repasses"
                className="alert-link btn btn-primary"
              >
                Ver novos repasses
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertPass;
